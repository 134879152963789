import axios from 'axios';

// const axiosServices = axios.create({ baseURL: 'http://localhost:8800/api/' });

// Dev server API's
// const axiosServices = axios.create({ baseURL: 'https://node.gokarobaar.com/api/' });
// const axiosServices = axios.create({ baseURL: 'https://dev-api.inspectnbuy.com/api/' });

// Production server API
const axiosServices = axios.create({ baseURL: 'https://api.inspectnbuy.com/api/' });

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //
axiosServices.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('serviceToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosServices;